.edit_button {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin-bottom: 20px;
}
.user_info_item {
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_info_data {
  padding: 0 10px;
}

.user__editinput {
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem 0 0.75rem;
}
