.user_info_list_item > li{
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user__editinput {
    border: 1px solid transparent;
    padding: 0.5rem 0.75rem 0 0.75rem;
}