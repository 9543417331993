/*body{*/
/*    background-color: #414141 !important;*/
/*    color: #fff !important;*/
/*}*/
/*table td{*/
/*    color: #fff;*/
/*}*/

.history-header > form,
.create-new-user-section form {
  padding: 10px 20px;
}
.history-header > form input,
.create-new-user-section form input,
.form-select {
  min-width: 200px;
  max-height: 32px;
}
.history-header > form input,
.create-new-user-section form input {
  min-width: 200px;
}
.create-new-user-section,
.create-new-operation {
  margin: 20px 10px;
  position: relative;
  padding: 20px 10px;
}
.create-new-user-section .section-border {
  border: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  padding: 20px 10px;
  background: white;
}
.create-new-operation .section-border {
  border: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  padding: 20px 10px;
  background: white;
}

.section-border {
  border: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  padding: 20px 10px;
  background: white;
  overflow: hidden;
}
.create-new-user-section .client,
.create-new-operation .client {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(20%, -60%);
  background-color: white;
  padding: 5px;
}
.filter-btn {
  padding: 5px 30px;
}
table td {
  border-right: 1px solid #000;
  border-collapse: collapse;
}
.user-list-table table input[type="checkbox"] {
  transform: scale(1.5);
}
.max-width-100 {
  width: 100%;
}
.additional_tab form.hide {
  visibility: hidden;
}
.form-select {
  padding: 0.1rem 2.25rem 0.375rem 0.75rem;
}

@media (max-width: 1400px) {
  .history-header > form input,
  .create-new-user-section form input {
    min-width: 180px;
  }
  #getCurrentClientHistory .form-select {
    min-width: 180px;
  }
  #getCurrentClientHistory button {
    margin-top: 18px;
  }
}
@media (max-width: 900px) {
  .user-list-table,
  .history-data-table {
    overflow: scroll;
  }
}

.delete__button {
  background: none;
  border: none;
  text-align: center;
  transition: 0.5s;
  filter: opacity(0.5);
}

.delete__button:hover {
  filter: opacity(1);
}
.sidebar-nav-item a.nav-link {
  color: #6c757d !important;
  padding: 0;
}
.sidebar-nav-item a.nav-link.active {
  color: #007bff !important;
  background-color: transparent !important;
  padding: 0;
}

.text-red {
  color: red;
  font-weight: bold;
}
.text-green {
  color: green;
  font-weight: bold;
}

.clients__btn {
  padding: 4px 20px;
}

.cursor-pointer {
  cursor: pointer;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 0 15px;
}

.switch input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.fiz-face{
  margin-left: auto;
  margin-right: 15px;
}
.pswp__img {
  cursor: zoom-in;
  object-fit: contain !important;
}
.pdf-position{
  object-fit: contain !important;
  object-fit: scale-down;
  max-height: 50px;
}
.user__cart--block{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 450px) {
  .adminx-container .navbar{
    flex-direction: column;
    height: 108px !important;
  }
  .adminx-sidebar {
    top: 106px !important;
  }
  .fiz-face {
     margin-left: unset;
     margin-right: unset;
  }
}

