.black_list {
  background: #a23838;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.black_list h3 {
  color: #fff;
}
