.logo {
  max-width: 250px !important;
}

.header {
  color: #007bff;
  font-weight: 500;
}

.logout {
  font-size: 16px;
  color: red;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}

